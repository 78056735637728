import Arduino from '../assets/arduinoProject.jpg';
import calc from '../assets/calculatorWebapp.png';
import RamdMeme from '../assets/RandomMeme.png';
import Picture1 from '../assets/Picture1.png';
import Expressjs from '../assets/Expressjs.png';

export const ProjectList = [
    {
        name: "Arduino Video Game Project",
        image: Arduino,
        gitrepo:"https://github.com/MikeyMore/arduino-project",
        description:"This is my microprocessors project, its a videogame inspired by flappy bird. This game uses an LCD screen, a joystick and other electronics like resistors and potentiometers. The code used in this project uses the Liquid Crystal Display library.",
        skills: "Arduino, C++ and Electronics"
    },
    {
        name: "Calculator Web Application",
        image: calc,
        gitrepo: "https://github.com/MikeyMore/web-calculator",
        description: "This is a personal project I started to reinforce what I learned in my computer science degree.",
        skills: "Javascript, HTML and CSS"
    },
    {
        name: "Random meme Web App",
        image: RamdMeme,
        gitrepo: "https://github.com/MikeyMore/Random-Meme_Webapp",
        description: "This is a side project I started to kill boredom. Also this helped me understand a few CSS and JavaScript concepts.",
        skills: "Javascript, HTML and CSS"
    },{
        name: "AVRET_UI",
        image: Picture1,
        gitrepo: "https://github.com/MikeyMore/AVRET_UI",
        description: "This is my capstone project for my computer science degree. This project is a web application that allows the user to upload patient data to a database. This project uses a MySQL database, a express.js server and a React.js front-end.",
        skills: "Javascript, HTML, CSS, RouterDom and React.js"
    },
    {
        name: "AVRET_API",
        image: Expressjs,
        gitrepo: "https://github.com/MikeyMore/AVRET_UI",
        description: "This is my capstone project for my computer science degree. This project is a web application that allows the user to upload patient data to a database. This project uses a MySQL database, a express.js server and a React.js front-end.",
        skills: "Javascript, API, Express.js, MySQL and Node.js"
    }

]