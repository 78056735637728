import React from 'react';
import "../styles/Home.css";
import { Javascript, LinkedIn, Email, GitHub, Skateboarding, Surfing, AutoFixHigh, OndemandVideo, Gamepad} from '@mui/icons-material';
import Programmer from '../assets/PngItem_1127587.png';
import { useEffect, useState } from 'react';



function Home() {

    const [width, setWidth] = useState(1200);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

  return (
    <div className='home'>
        <div className='about'>
            <h2> Hi, My name is Michael</h2>
            <br></br>
            <br></br>
            
            <div className='prompt'>
                <br></br>
                <p>
                    <br></br>
                    Welcome to my corner on the web! I'm currently on the exciting journey of completing my Bachelor's degree in Computer Science and Engineering. What drives me? A deep fascination with the limitless potential of technology and a commitment to mastering the art and science of full-stack development.
                </p>
                <Email />
                <GitHub />
                <LinkedIn />
                
            </div>
        </div>
        <div className='skills'>
            <h1>Skills</h1>
            <br></br><img className='programmer' style={width > 700 ? {height: '500px', width: '650px'} : {height: '200px', width: '350px'}} src={Programmer} />
            <ol className='list'>
                <li className='item'>
                    <h2>Things I like:</h2>
                    <span><Skateboarding /> Skateboarding 
                    <br></br> <Gamepad /> Gaming 
                    <br></br> <OndemandVideo /> Anime
                    <br></br> <OndemandVideo /> Netflix
                    <br></br> <Surfing /> Bodyboarding 
                    <br></br> <AutoFixHigh /> Close-up magic</span>
                </li>
                <li className='item'>
                    <h2>Front-end Skills </h2>
                    <span>
                    <br></br>Node.js experience
                    <br></br>HTML5 expertise
                    <br></br>CSS Proficiency
                    <br></br> JSP (Java Server Page)
                    <br></br>Java Programming
                    <br></br>JavaScript proficiency
                    <br></br>Bootstrap framework
                    <br></br>React.js knowledge
                    <br></br>JavaScript Experience
                    <br></br>Wordpress
                    <br></br>Typescript
                    <br></br>React Native
                    <br></br>Next.js
                    </span>
                </li>
                <li className='item'>
                    <h2>Back-end Skills</h2>
                    <span> 
                        <br></br>Node.js experience
                        <br></br>JavaScript Experience
                        <br></br>AWS cloud services
                        <br></br> TomCat
                        <br></br> MySQL
                        <br></br> Express.js familiarity
                    </span>
                </li>
                <li className='item'>
                    <h2>Other technologies/skills:</h2>
                    <span> Unix/Linux
                    <br></br>Jest testing library
                    <br></br> Cisco Packet tracer
                    <br></br> MatLab/Octave
                    <br></br> Java
                    <br></br> C++
                    <br></br> Python
                    <br></br> Arduino
                    <br></br> Raspberry Pi
                    <br></br> Microsoft Office
                    <br></br> Unity
                    <br></br>Agile Software Development
                    <br></br>Quality Assurance
                    <br></br>Team Collaboration
                    <br></br>Git version control
                    <br></br>GitHub collaboration
                    </span>
                </li>
                <li className='item'>
                    <h2>Short term goals</h2>
                    <span> CCNA certification
                    <br></br> CompTIA Network+
                    <br></br> CompTIA Security+</span>
                </li>
            </ol>
            
        </div>
        <h2 className='coffeePlz'>CoffeePlz</h2>
            <a href='https://www.buymeacoffee.com/michaelmore'>
                <div className='coffee' ></div>
            </a>
    </div>
  )
}

export default Home